import React from 'react';
import SEO from '../components/core/meta/SEO';

const ImpattoPage = () => {
  return (
    <>
      <SEO url={'/impatto'} title={'Impatto'} />
    </>
  );
};

export default ImpattoPage;
